import { useState, useEffect, useRef } from "react";
import { Channel } from "phoenix";

import { View } from "react-native";

import { useHistory, useParams } from "react-router-dom";

import { useQueryClient } from "@tanstack/react-query";

import {
  OmniSearchContainer,
  OmniSearchContent,
  OmniSearchFlatList,
  OmniSearchFlatListItem,
  OmniSearchInput,
  OmniSearchListItem,
  OmniSearchLoadingListItem,
  useTheme,
  useOmnisearchFlatList,
  TabProvider,
  TabsNavigation,
} from "@smartrent/ui";

import { hexToRgba } from "@smartrent/utils";

import { PaginatedResponse } from "@smartrent/hooks";

import { instance } from "@/react/hooks/api";

import * as links from "@/lib/links";
import { Id } from "@/react/types";

type GlobalSearchProps = Omit<ReturnType<typeof useGlobalSearch>, "onOpen">;

type Omnisearch = {
  id: string;
  label: string;
  value: string;
  detail: string;
  onPress: () => void;
};

type UnitResult = {
  index_token: string;
  id: string;
  name: string;
  group_name: string;
};

type ResidentResult = {
  index_token: string;
  id: string;
  unit_id: string;
  first_name: string;
  last_name: string;
  resident_id: string;
  group_marketing_name: string;
  unit_marketing_name: string;
};

type HubResult = {
  index_token: string;
  id: string;
  serial: string;
  type: string;
  firmware: string;
  unit_id: string;
};

export enum SearchType {
  resident = "resident",
  unit = "unit",
  hub = "hub",
  all = "all",
}

function Loading() {
  return <OmniSearchLoadingListItem />;
}

function NoRecords() {
  return <OmniSearchListItem label="No Results" value=" " to="/" />;
}

const enum HubType {
  virtual = "virtual",
  zipato = "zipato",
  smartrent = "smartrent",
}

const getFormattedHubType = (type: string) => {
  switch (type) {
    case HubType.virtual:
      return "Virtual";
    case HubType.zipato:
      return "Zipato";
    case HubType.smartrent:
      return "SmartRent";
    default:
      return type;
  }
};

/**
 * @param searchType - The type of search to perform; i.e. which search tab is opened by default the first time.
 * @param unitLink - A function that calculates the link where the user will be sent when a unit result is clicked.
 * @param residentLink - A function that calculates the link where the user will be sent when a resident result is clicked.
 */
export function useGlobalSearch({
  searchType = SearchType.all,
  unitLink,
  residentLink,
}: {
  searchType?: SearchType;
  unitLink?: (groupId: Id, unitId: Id) => string;
  residentLink?: (groupId: Id, unitId: Id, residentId: Id) => string;
} = {}) {
  const { groupId } = useParams<{ groupId: string }>();

  const history = useHistory();

  const [type, setType] = useState<SearchType>(searchType);

  const channel = useRef<Channel & { topic: string }>();

  useEffect(() => {
    const channelName = "search";

    if (
      channel?.current?.state !== "joined" ||
      channel?.current?.topic !== "search"
    ) {
      channel.current = window.SmartRent.socket.joinChannel(
        channelName
      ) as Channel & { topic: string };
    }
  });

  const transformByIndex: {
    [key: string]: any;
  } = {
    residents: (resident: ResidentResult): Omnisearch => {
      return {
        id: resident.id,
        label: `${resident.first_name} ${resident.last_name}`,
        value: `${resident.group_marketing_name} ${resident.unit_marketing_name}`,
        detail: "Residents",
        onPress: () => {
          channel.current.push("result-clicked", { entity: "resident" });
          history.push(
            residentLink
              ? residentLink(groupId, resident.unit_id, resident.id)
              : links.group.units.residents.single({
                  groupId,
                  unitId: resident.unit_id,
                  residentId: resident.id,
                })
          );
        },
      };
    },
    units: (unit: UnitResult): Omnisearch => {
      return {
        id: unit.id,
        label: unit.name,
        value: unit.group_name,
        detail: "Units",
        onPress: () => {
          channel.current.push("result-clicked", { entity: "unit" });
          history.push(
            unitLink
              ? unitLink(groupId, unit.id)
              : links.group.units.show({ groupId, unitId: unit.id })
          );
        },
      };
    },
    hubs: (hub: HubResult): Omnisearch => {
      return {
        id: hub.id,
        label: hub.serial,
        value: `${getFormattedHubType(hub.type)}${
          hub.firmware ? " - " + hub.firmware : ""
        }`,
        detail: "Hubs",
        onPress: () => {
          channel.current.push("result-clicked", { entity: "hub" });
          history.push(
            links.unit_install.addNewDevice({ groupId, unitId: hub.unit_id })
          );
        },
      };
    },
  };

  const flatListProps = useOmnisearchFlatList(
    ["omnisearch", type],
    async ({
      pageParam,
      searchQuery,
    }): Promise<PaginatedResponse<OmniSearchFlatListItem>> => {
      return instance()
        .get<PaginatedResponse<UnitResult | ResidentResult | HubResult>>(
          `/global-search`,
          {
            params: {
              query: searchQuery,
              groupId,
              type,
              page: pageParam,
            },
          }
        )
        .then(({ data }) => {
          return {
            ...data,
            records: data.records.map(
              (record: UnitResult | ResidentResult | HubResult) =>
                transformByIndex[record.index_token](record)
            ),
          };
        });
    }
  );

  return {
    ...flatListProps,
    searchType: type,
    setSearchType: setType,
  };
}

export const GlobalSearch = ({
  visible,
  focusedIndex,
  searchQuery,
  flatListQuery,
  onClose,
  onSearch,
  onInputKeyDown,
  onItemKeyDown,
  searchInputRef,
  contentRef,
  searchType,
  setSearchType,
}: GlobalSearchProps) => {
  const queryClient = useQueryClient();

  const { colors } = useTheme();

  return (
    <OmniSearchContainer visible={visible} onClose={onClose}>
      <OmniSearchInput
        ref={searchInputRef}
        isFetching={flatListQuery.isLoading}
        onClose={onClose}
        value={searchQuery}
        onChangeSearch={onSearch}
        onKeyPress={onInputKeyDown}
      />
      <OmniSearchContent ref={contentRef}>
        <View
          style={{
            borderBottomColor: hexToRgba(colors.borderLow, 0.4),
            borderBottomWidth: 1,
          }}
        >
          <TabProvider>
            <TabsNavigation
              align="flex-start"
              initialActiveTabId={searchType}
              items={[
                {
                  id: "all",
                  label: "All",
                  onPress: () => {
                    setSearchType(SearchType.all);
                    searchInputRef.current?.focus();
                    queryClient.removeQueries({
                      queryKey: ["omnisearch", SearchType.all],
                    });
                  },
                },
                {
                  id: "resident",
                  label: "Residents",
                  onPress: () => {
                    setSearchType(SearchType.resident);
                    searchInputRef.current?.focus();
                    queryClient.removeQueries({
                      queryKey: ["omnisearch", SearchType.resident],
                    });
                  },
                },
                {
                  id: "unit",
                  label: "Units",
                  onPress: () => {
                    setSearchType(SearchType.unit);
                    searchInputRef.current?.focus();
                    queryClient.removeQueries({
                      queryKey: ["omnisearch", SearchType.unit],
                    });
                  },
                },
                {
                  id: "hub",
                  label: "Hubs",
                  onPress: () => {
                    setSearchType(SearchType.hub);
                    searchInputRef.current?.focus();
                    queryClient.removeQueries({
                      queryKey: ["omnisearch", SearchType.hub],
                    });
                  },
                },
              ]}
            />
          </TabProvider>
        </View>
        <OmniSearchFlatList
          onKeyDown={onItemKeyDown}
          onClose={onClose}
          focusedIndex={focusedIndex}
          LoadingItemComponent={Loading}
          ListEmptyComponent={NoRecords}
          numberOfLines={1}
          {...flatListQuery}
        />
      </OmniSearchContent>
    </OmniSearchContainer>
  );
};
