import React, { useEffect } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { useMedia } from "react-use";

import {
  ActivityIndicator,
  Spacer,
  TabItem,
  TabProvider,
  TabsNavigation,
  useTheme,
} from "@smartrent/ui";

import { useHistory } from "react-router-dom";

import {
  CheckPermissionWithRedirect,
  CheckPermissionWithRedirectProps,
} from "@/react/common/components/CheckPermissionsWithRedirect";
import { useDocumentTitle } from "@/react/hooks/document-title";
import {
  useIsUnifiedDesignFlagEnabled,
  useSidebarWidth,
} from "@/react/hooks/useIsUnifiedDesignFlagEnabled";
import { TopNav } from "@/react/common/page_elements";
import { NavLinkProps } from "@/react/types/NavLink";

import { useZendeskChat } from "./zendesk/ZendeskChatContext";

export const RESTRUCTURE_BREAKPOINT = "(max-width: 768px)";

export interface ReactPageProps {
  zendeskChat?: boolean;
  topBar?: React.ReactNode;
  actionButton?: React.ReactNode;
  tabItems?: NavLinkProps[];
  hideSidebar?: boolean;
  children?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  childrenContainerStyle?: StyleProp<ViewStyle>;
  loading?: boolean;
  title?: string;
}

export const ReactPage = ({
  topBar,
  actionButton,
  tabItems,
  children,
  style,
  hideSidebar,
  contentContainerStyle,
  childrenContainerStyle,
  loading = false,
  zendeskChat = true,
  title,

  // permissions related
  permission,
  permissionType,
  redirectUrl,
}: ReactPageProps & CheckPermissionWithRedirectProps) => {
  const { setShouldShow } = useZendeskChat();
  useDocumentTitle(title);
  const isMobile = useMedia(RESTRUCTURE_BREAKPOINT);
  const sidebarWidth = useSidebarWidth();
  const isUnifiedDesignFlagEnabled = useIsUnifiedDesignFlagEnabled();
  const isSidebarHidden = hideSidebar && !isUnifiedDesignFlagEnabled;
  const sideBarOffset = !isMobile && !isSidebarHidden ? sidebarWidth : 0;

  const { colors } = useTheme();

  useEffect(() => {
    setShouldShow(zendeskChat);
  }, [zendeskChat, setShouldShow]);

  return (
    <View style={[styles.container, style]}>
      <View
        style={[
          styles.pageSection,
          { marginLeft: sideBarOffset },
          contentContainerStyle,
        ]}
      >
        {isUnifiedDesignFlagEnabled ? (
          <View style={{ borderBottomWidth: 1, borderColor: colors.border }}>
            <TopNav>{actionButton}</TopNav>
            {tabItems?.length > 0 ? <Tabs items={tabItems} /> : null}
          </View>
        ) : (
          topBar
        )}
        <CheckPermissionWithRedirect
          permission={permission}
          permissionType={permissionType}
          redirectUrl={redirectUrl}
        >
          {loading ? (
            <View style={styles.centered}>
              <ActivityIndicator size={64} />
            </View>
          ) : (
            <View style={[styles.childrenContainer, childrenContainerStyle]}>
              {children}
            </View>
          )}
        </CheckPermissionWithRedirect>
        {zendeskChat ? <Spacer height={64} /> : null}
      </View>
    </View>
  );
};

type TabsProps = {
  items: NavLinkProps[];
};

const Tabs = ({ items }: TabsProps) => {
  const { spacing } = useTheme();
  const convertTabs = useConvertTabs();

  return (
    <TabProvider>
      <TabsNavigation
        initialActiveTabId={items.find((item) => item.isActive)?.title}
        style={{ paddingLeft: spacing.xl }}
        align="flex-start"
        items={convertTabs(items)}
      />
    </TabProvider>
  );
};

/**
 * Converts old tab items to the new tab format while filtering out hidden tabs.
 *
 * This function is **temporary** and should be removed once all tabs in the app use the new format directly.
 */
const useConvertTabs = () => {
  const history = useHistory();

  return (oldTabs: NavLinkProps[]): TabItem[] => {
    return oldTabs
      .filter((tab) => tab.visible !== false)
      .map((tab) => ({
        id: tab.title,
        label: tab.title,
        onPress: () => history.push(tab.route),
      }));
  };
};

const styles = StyleSheet.create({
  container: {
    minHeight: "100%",
  },
  pageSection: {
    flexGrow: 1,
    minHeight: "100%",
  },
  childrenContainer: {
    padding: 24,
  },
  centered: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
