import { permissions } from "@/react/common/utils/permissions";
import { usePermission } from "@/react/hooks/permissions";
import { useProviderConfigurationsQuery } from "@/react/queries/mgmt-api/provider-configurations";

import { useFeatureFlagEnabled } from "../queries/mgmt-api/feature-flags";
import { useOrganization } from "../queries/mgmt-api/organization";
import { useCurrentUser } from "../context/mgmt-api/user";
import { useUsersVacantAccess } from "../queries/mgmt-api/vacant-access/vacant-manager-access";

export const useCanViewMyAccess = () => {
  const { data: org } = useOrganization();
  const current_user = useCurrentUser();
  const canViewAccessCodes = usePermission(permissions.access_codes.view);
  const canReceiveVacantCodes = usePermission(
    permissions.access_codes.receive_vacant_code
  );
  const {
    data: { enabled: vacantAccessEnabled },
    isFetched,
  } = useFeatureFlagEnabled({
    flag: "vacant_access",
    organizationId: org?.id,
  });
  const { data: vacantAccess, isLoading: userVacantAccessIsLoading } =
    useUsersVacantAccess(current_user?.id);

  const queryFilters = {
    enabled: true,
    providers_that_support: "property_access",
  };
  const reactQueryOpts = {
    // We only need to run this query if the employee has the
    // view access code permission AND does NOT have the permission
    // to receive vacant codes.
    //
    // This will prevent it from running for users like Super Admins,
    // as well as employees who we already know can view "My Access"
    // because they can receive vacant access codes.
    enabled: canViewAccessCodes && !canReceiveVacantCodes,
  };

  const {
    isLoading,
    isSuccess,
    data: { total_records: totalRecords = 0 } = {},
  } = useProviderConfigurationsQuery(queryFilters, reactQueryOpts);

  if (current_user?.is_last_org_admin) {
    return { canView: false, isLoading: false };
  }

  if (!canViewAccessCodes) {
    return { canView: false, isLoading: false };
  }

  if (
    canReceiveVacantCodes ||
    (vacantAccessEnabled && vacantAccess?.records.length)
  ) {
    return { canView: true, isLoading: false };
  }

  if (isLoading || !isFetched || userVacantAccessIsLoading || !isSuccess) {
    return { canView: false, isLoading: true };
  }

  return {
    canView: totalRecords > 0 && canViewAccessCodes,
    isLoading: false,
  };
};
